<template>
  <v-list-item
    @click="dialog = true"
    two-line
    class="gene-card"
    :class="gxGeneResultsDict[gene.symbol].label"
  >
    <gene-result-dialog
      v-if="dialog"
      @close="dialog = false"
      :active="dialog"
      :geneData="geneData"
    />

    <v-list-item-content class="d-flex">
      <div class="mb-n1">
        <v-icon
          :color="gxGeneResultsDict[gene.symbol].label"
          class="float-right absolute"
          >mdi-circle</v-icon
        >
        <p class="mb-n1 font-weight-semibold">{{ gene.symbol }}</p>
        <span style="font-size: .8rem" class="">{{
          gxGeneResultsDict[gene.symbol].result
        }}</span>
      </div>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import GeneResultMixin from "@/mixins/GeneResultMixin.vue";
import { mapGetters } from "vuex";
import GeneResultDialog from "@/components/mylogy/pgx_results/GeneResultDialog.vue";

export default {
  name: "GeneCard",
  props: ["gene"],
  components: {
    GeneResultDialog
  },
  mixins: [GeneResultMixin],
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    ...mapGetters("gxGeneResults", ["gxGeneResultsDict"]),
    geneData() {
      return {
        ...{ gene: this.gene },
        ...{ geneId: this.gene.id },
        ...{ result: this.gxGeneResultsDict[this.gene.symbol] }
      };
    }
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
.gene-card {
  width: 100%;
  border: solid #00000033 !important;
  border-width: 1px 2px 6px 2px !important;
  background: white !important;

  &.green {
    border-bottom-color: #4caf50 !important;
    box-shadow: 0 -2px 15px -8px #4caf50;
  }
  &.yellow {
    border-bottom-color: #ffeb3b !important;
    box-shadow: 0 -2px 15px -8px #b8a500;
  }
  &.orange {
    border-bottom-color: orange !important;
    box-shadow: 0 -2px 15px -8px orange;
  }
  &.red {
    border-bottom-color: #f44336 !important;
    box-shadow: 0 -2px 15px -8px #f44336;
  }

  /*Fix para a altura do ultimo item da lista*/
  max-height: 0px;

  .absolute {
    position: absolute;
    right: 10px;
  }
}
.gene-card.v-list-item--two-line {
  min-height: 55px;
}
</style>
