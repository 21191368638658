<template>
  <v-row @click="$emit('show-drug-dialog', drug)" class="">
    <v-col cols="3" sm="1">
      <ColorScore :score="drug.drugResult.result" />
    </v-col>
    <v-col>
      <div class="ml-2"><slot/></div>
    </v-col>
  </v-row>
</template>

<script>


import ColorScore from "@/components/mylogy/ColorScore.vue";
export default {
  props: ["drug"],
  components: {

    ColorScore
  },
  data() {
    return {
      dialog: false
    };
  }
};
</script>
<style lang="scss" scoped>
.v-icon {
  height: 1.8rem;
}
</style>
