<template>
  <reactive-base
    app="kb-drugs"
    :url="url"
    :credentials="credentials"
    type="_doc"
    class="d-flex"
  >
    <DrugResultDialog
      v-if="drugDialog"
      @close="drugDialog = false"
      :active="drugDialog"
      :drug="selectedDrug"
    />
    <!-- <v-icon large color="white" class="mr-4 mt-1 d-none d-md-flex">mdi-magnify</v-icon> -->
    <data-search
      componentId="DrugSearchSensor"
      :placeholder="$t('buscar_por_medicamento')"
      :showClear="false"
      :showIcon="false"
      :dataField="[
        `Drug_Name_${this.$i18n.locale.toUpperCase()}`,
        `Drug_Name_${this.$i18n.locale.toUpperCase()}.autosuggest`,
        'Brandnames',
        'Brandnames.autosuggest'
      ]"
      class="grow"
    >
      <v-sheet
        class=""
        width="100%"
        slot="render"
        rounded="sm"
        elevation="18"
        slot-scope="{
          error,
          loading,
          downshiftProps: {
            isOpen,
            highlightedIndex,
            getItemProps,
            getItemEvents
          },
          data: suggestions
        }"
        v-bind:class="{ suggestions: isOpen }"
      >
        <ul v-if="isOpen">
          <li
            class="px-2 py-2"
            style="{ background-color: highlightedIndex ? 'grey' : 'transparent' }"
            v-for="suggestion in renderSuggestions(suggestions || [])"
            v-bind="getItemProps({ item: suggestion })"
            :key="suggestion._id"
          >
            <drug-input-search-result
              :drug="suggestion.value"
              @show-drug-dialog="showDrugDialog"
            >
              {{ suggestion.label }}
            </drug-input-search-result>
          </li>
        </ul>
      </v-sheet>
    </data-search>
  </reactive-base>
</template>

<script>
import DrugResultDialog from "@/components/mylogy/pgx_results/DrugResultDialog.vue";
import DrugInputSearchResult from "@/components/mylogy/pgx_results/DrugInputSearchResult.vue";
import { CRED, TYPE, URL } from "@/helpers/constants";
import { mapGetters } from "vuex";

export default {
  components: { DrugInputSearchResult, DrugResultDialog },
  data() {
    return {
      credentials: CRED,
      url: URL,
      drugDialog: false,
      selectedDrug: null
    };
  },
  computed: {
    ...mapGetters("pgxDrugResults", ["pgxDrugResult"])
  },
  methods: {
    showDrugDialog(drug) {
      this.selectedDrug = drug;
      this.drugDialog = true;
    },
    renderSuggestions(suggestions) {
      let self = this;

      return suggestions
        .filter(s => {
          return !s.source.Hide_In_PGX;
        })
        .map(s => {
          return {
            label:
              s.source["Drug_Name_" + self.$i18n.locale.toUpperCase()] +
              " (" +
              s.label +
              ")",
            value: {
              ...{ source: s.source },
              ...{ drugResult: self.pgxDrugResult(s.source.Slug) }
            },
            key: s._id
          };
        });
    }
  }
};
</script>

<style lang="scss">
#DrugSearchSensor-input {
  background-image: url("~@/assets/img/search-icon-white.png") !important;
  background-size: 25px !important;
  background-repeat: no-repeat !important;
  background-position-y: center !important;
  background-position-x: 10px !important;
  padding-left: 50px !important;
}

.v-sheet ul li {
  cursor: pointer;
}
</style>
