var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('reactive-base',{staticClass:"d-flex",attrs:{"app":"kb-drugs","url":_vm.url,"credentials":_vm.credentials,"type":"_doc"}},[(_vm.drugDialog)?_c('DrugResultDialog',{attrs:{"active":_vm.drugDialog,"drug":_vm.selectedDrug},on:{"close":function($event){_vm.drugDialog = false}}}):_vm._e(),_c('data-search',{staticClass:"grow",attrs:{"componentId":"DrugSearchSensor","placeholder":_vm.$t('buscar_por_medicamento'),"showClear":false,"showIcon":false,"dataField":[
      ("Drug_Name_" + (this.$i18n.locale.toUpperCase())),
      ("Drug_Name_" + (this.$i18n.locale.toUpperCase()) + ".autosuggest"),
      'Brandnames',
      'Brandnames.autosuggest'
    ]},scopedSlots:_vm._u([{key:"render",fn:function(ref){
      var error = ref.error;
      var loading = ref.loading;
      var ref_downshiftProps = ref.downshiftProps;
      var isOpen = ref_downshiftProps.isOpen;
      var highlightedIndex = ref_downshiftProps.highlightedIndex;
      var getItemProps = ref_downshiftProps.getItemProps;
      var getItemEvents = ref_downshiftProps.getItemEvents;
      var suggestions = ref.data;
return _c('v-sheet',{class:{ suggestions: isOpen },attrs:{"width":"100%","rounded":"sm","elevation":"18"}},[(isOpen)?_c('ul',_vm._l((_vm.renderSuggestions(suggestions || [])),function(suggestion){return _c('li',_vm._b({key:suggestion._id,staticClass:"px-2 py-2",staticStyle:{"{ background-color":"highlightedIndex ? 'grey' : 'transparent' }"}},'li',getItemProps({ item: suggestion }),false),[_c('drug-input-search-result',{attrs:{"drug":suggestion.value},on:{"show-drug-dialog":_vm.showDrugDialog}},[_vm._v(" "+_vm._s(suggestion.label)+" ")])],1)}),0):_vm._e()])}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }